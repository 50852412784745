import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'common/spinners';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import {
  ColumnHeader,
  SortableColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import formatLongDate from 'src/common/utils/Dates/formatLongDate';
import _ from 'lodash';

export function ExternalScreeningsTable(props) {
  const {
    screenings,
    isFetching,
    sortDirection,
    sortBy,
    setSort,
    paging,
    onCellClick,
  } = props;

  return (
    <div className="dashboard-inner-content">
      {isFetching && _.isEmpty(screenings) ? (
        <Spinner />
      ) : (
        <div className="p-8 pt-4">
          <Table
            id="external-screenings-table"
            className="bg-white rounded-t-lg border border-solid border-light-border-blue"
          >
            <HeaderRow>
              <ColumnHeader className="w-1/6">Client Name</ColumnHeader>
              <ColumnHeader className="w-1/6">Screening Type</ColumnHeader>
              <ColumnHeader className="w-1/6">Submitter</ColumnHeader>
              <ColumnHeader className="w-1/12">Needs Identified</ColumnHeader>
              {/* <SortableColumnHeader
                colName="needs_identified"
                className="w-1/12"
                label="Needs Identified"
                sortBy={sortBy}
                sortDirection={sortDirection}
                setSort={setSort}
              >
                Needs Identified
              </SortableColumnHeader> */}
              <SortableColumnHeader
                colName="updated_at"
                className="w-1/12"
                label="Date Received"
                sortBy={sortBy}
                sortDirection={sortDirection}
                setSort={setSort}
              >
                Date Received
              </SortableColumnHeader>
              <ColumnHeader className="w-1/12">Assigment Status</ColumnHeader>
            </HeaderRow>
            <TableBody>
              {screenings.map((screen) => (
                <DataRow
                  className="cursor-pointer hover:bg-light-fill-blue hover:bg-opacity-50"
                  key={`screen-row-${screen.id}`}
                  onClick={() => onCellClick(screen)}
                >
                  <DataCell className="w-1/6">{screen.client_name}</DataCell>
                  <DataCell className="w-1/6">
                    {screen.type}
                  </DataCell>
                  <DataCell className="w-1/6">{screen.submitter_name}</DataCell>
                  <DataCell className="w-1/12">
                    {screen.identified_needs_count}
                  </DataCell>
                  <DataCell className="w-1/12">{formatLongDate(screen.updated_at)}</DataCell>
                  <DataCell className="w-1/12">{screen.assigned}</DataCell>
                </DataRow>
              ))}
            </TableBody>
          </Table>
          {paging.total_count === 0 && (
            <EmptyTable
              className={
                'p-6 border border-t-0 border-solid border-light-border-blue'
              }
              message={'No Results Found.'}
            />
          )}
        </div>
      )}
    </div>
  );
}

ExternalScreeningsTable.propTypes = {
  sortBy: PropTypes.string.isRequired,
  screenings: PropTypes.array.isRequired,
  sortDirection: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  setSort: PropTypes.func.isRequired,
  paging: PropTypes.shape({
    total_count: PropTypes.number.isRequired,
  }).isRequired,
  onCellClick: PropTypes.func.isRequired,
};

ExternalScreeningsTable.defaultProps = {
};

export default ExternalScreeningsTable;
