import React, { useEffect, useState } from 'react';
import { browserHistory } from 'src/common/utils/browserHistory';
import _ from 'lodash';
import { useExternalScreenings } from 'src/actions/Screening/Group';
import ExternalScreeningsTable from 'src/components/Dashboard/components/Screenings/ExternalScreeningsTable';
import FilterBar from 'src/components/Dashboard/components/FilterBar';

function ExternalScreenings() {
  const [page, setPage] = useState(1);
  const [{ sortBy, sortDirection }, setSort] = useState({
    sortBy: 'updated_at',
    sortDirection: 'desc',
  });
  const [filters, setFilters] = useState([]);
  const [templates, setTemplates] = useState([]);

  const { data, isFetching, refetch } = useExternalScreenings(
    {
      page,
      filters,
      sortBy,
      sortDirection,
      templates,
    },
  );

  const { screenings, paging } = data || { screenings: [], paging: {} };

  useEffect(() => {
    if (data?.filters) setFilters(data.filters);
    if (data?.templates) setTemplates(data.templates);
  }, [data?.filters]);

  const onCellClick = (screen) => {
    if (screen) {
      const screenId = screen.id;
      const clientId = screen.subject.id;
      browserHistory.push(
        `/screenings/v2/facesheet/${clientId}/submission/${screenId}`,
      );
    }
  };
  useEffect(() => {
    refetch();
  }, [filters, page, sortBy, sortDirection]);

  const onFiltersChange = (key, newFilters) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.key === key) {
        return {
          ...filter,
          options: filter.options.map((option) => ({
            ...option,
            initial: newFilters.includes(option.value),
          })),
        };
      }
      return filter;
    });

    setFilters(updatedFilters);
  };

  const onNextClick = () => {
    setPage(page + 1);
  };

  const onPrevClick = () => {
    setPage(page - 1);
  };

  const setSorting = (sort) => {
    setSort(sort);
  };

  return (
    <div>
      <FilterBar
        filters={_.sortBy(filters, 'name')}
        onFiltersChange={onFiltersChange}
        paging={paging}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        isFetching={isFetching && !_.isEmpty(screenings)}
      />
      <ExternalScreeningsTable
        screenings={screenings}
        isFetching={isFetching}
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSort={setSorting}
        paging={paging}
        onCellClick={onCellClick}
      />
    </div>
  );
}

ExternalScreenings.propTypes = {};

ExternalScreenings.defaultProps = {};

export default ExternalScreenings;
