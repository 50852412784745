import { screeningsApiClient, coreApi } from 'src/api/config';
import { useQuery } from 'react-query';
import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';
import {
  fetchUsersInfo,
  fetchScreeningsTemplates,
} from 'src/actions/Screening/Group';

const LIMIT = 25; // maximum allowed by screening-ingestion

const getOffset = (pageNumber) => (pageNumber - 1) * LIMIT;
const getNextPage = (total, currentPage, size) => (
  total > currentPage * size ? currentPage + 1 : undefined
);
const getPrevPage = (currentPage) => (
  currentPage > 1 ? currentPage - 1 : undefined
);

const defaultOptions = {
  filters: [],
  page: 1,
  // type: 'screening',
  offset: getOffset(1),
  limit: LIMIT,
  target: '',
  source: 'self_screen,external',
};

async function verifySelfScreenClientConsent(screen, client, consentedClients) {
  const consented = consentedClients;
  if (consented.indexOf(client.id) < 0) {
    const response = await coreApi.createRecord('consent', {
      state: 'accepted',
      consent_method: 'written',
      submission_method: 'file_upload',
      person: client.id,
    });
    const id = get(response, 'data.data.id');
    console.log(id);
    // in case there are more than one screen for unconsented client
    consented.push(client.id);
    // await coreApi.uploadFile('consent', id, documentsFileList[0]);
    // await createDocumentConsent({contactId: client.id, documentsFileList: [], consentMethod: 'written'});
  }
  return consented;
}

function loadFilters(templates) {
  const templatesFilter = templates?.map((f) => ({
    label: f.title,
    value: f.type,
  }));
  const typeFilter = {
    key: 'type',
    name: 'Screening Type',
    pluralName: 'Screening Types',
    options: templatesFilter,
  };
  const sourceFilter = {
    key: 'source',
    name: 'Screening Source',
    pluralName: 'Screening Sources',
    options: [
      { value: 'external', label: 'External' },
      { value: 'self_screen', label: 'Self Screen' },
    ],
  };
  const assignmentFilter = {
    key: 'is_assigned',
    name: 'Assignment Status',
    pluralName: 'Assigments Status',
    options: [
      { value: 'true', label: 'Assigned' },
      { value: 'false', label: 'Unassigned' },
    ],
  };
  return [assignmentFilter, sourceFilter, typeFilter];
}

const getFilters = (filters, params) => (
  filters.reduce((newParams, filter) => {
      const selectedValues = filter.options
        .filter((option) => option.initial)
        .map((option) => option.value);

      if (selectedValues.length > 0) {
        if (filter.key === 'is_assigned' && selectedValues.length > 1) {
          return { ...newParams, [filter.key]: null };
        }
        return { ...newParams, [filter.key]: selectedValues.join(',') };
      }
      return newParams;
    }, { ...params }));

async function fetchUserNames(screenings) {
  if (screenings) {
    const clientIds = [
      ...new Set(screenings.map((screen) => screen.subject.id).filter(Boolean)),
    ];

    return fetchUsersInfo(clientIds, []);
  }
  return [];
}

export const SCREENINGS_KEYS = {
  EXTERNAL_SCREENINGS: 'external-screenings',
};

export const useExternalScreenings = (options = defaultOptions) => (
  useQuery(
    [SCREENINGS_KEYS.EXTERNAL_SCREENINGS, options.page],
    async () => {
      try {
        let queryParams = {
          type: 'screening',
          status: 'complete',
          limit: LIMIT,
          offset: getOffset(options.page),
          sort_by: options.sortBy,
          sort_direction: options.sortDirection,
        };

        let filterOptions = options.filters;
        queryParams = getFilters(filterOptions, queryParams);

        if (!queryParams.source) queryParams.source = defaultOptions.source;

        let newTemplates = options.templates;
        // fetch type filters if empty
        if (filterOptions.length === 0) {
          newTemplates = await fetchScreeningsTemplates();
          filterOptions = loadFilters(newTemplates);
        }

        const response = await screeningsApiClient.get('/screenings', {
          params: queryParams,
        });

        const screensList = get(response, 'data.screens', []);

        const pageData = {
          per: response.data.limit,
          next_page: getNextPage(
            response.data.total,
            options.page,
            response.data.limit,
          ),
          prev_page: getPrevPage(options.page),
          total_count: response.data.total,
          current_page: options.page,
        };

        const { clients } = await fetchUserNames(screensList);

        let consentedClients = [];

        const screenings = screensList.map((screen) => {
          const client = clients.find((user) => user.id === screen.subject.id);

          if (screen.source === 'self_screen' && client?.consent === null) {
            consentedClients = verifySelfScreenClientConsent(
              screen,
              client,
              consentedClients,
            );
          }

          const submitter_name =
            screen.source === 'external' ?
              `${screen.organization_name || ''} (External)` :
              'Client';

          const type = newTemplates.find(
            (template) => template.id === screen.template_id,
          )?.title;

          return {
            ...screen,
            client_name: client?.full_name,
            submitter_name,
            permissions: { can_view: { authorization: true } },
            assigned: screen.assigned_to_id ? 'Assigned' : 'Unassigned',
            type,
          };
        });

        const data = {
            screenings,
            paging: pageData,
            filters: filterOptions,
            templates: newTemplates,
        };

        return data;
      } catch (error) {
        Notifier.handleErrors(error);
        return error;
      }
    // }
  },
  {
    staleTime: 30 * 1000,
    refetchInterval: 30 * 1000,
    retry: false,
    keepPreviousData: true,
  },
));

export default useExternalScreenings;
